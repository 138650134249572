import React from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"
import Image from "../components/image"
import "../assets/payment.css"

const Payment = () => (
  <div className="payments">
    <div className="payment-header">
      <h1 className="payments-header_name">Virtual Cooking Classes</h1>
      <p>Join Chef Terrell Cole on a tasty 3 course cooking lesson</p>

    </div>

    <div className="payment-options">
      <h3>
        Payment Options
      </h3>

      <p>In the comment section of your prefered method of payment <span>please add your email address.</span></p>
      <p>Once your payment is submitted <span>you'll receive an email</span> with a link to your virtual class.</p>
      <p>Now let's Cook!</p>

      <div className="payment-types">
        <div className="payment-option-container">
          <a href="https://venmo.com/Terrell-Cole-1">
            <Image filename="venmo_logo_blue.png" />
            <Image filename="dark-horse-barcode.png" />
          </a>
          <p>
            Pay through the app
          </p>
        </div>

        <div className="payment-option-container">
          <Image filename="zelle-logo.jpg" />
          <p>
            Pay via Zelle / Quickpay
          </p>
          <p>Darkhorsepastries@gmail.com </p>
        </div>
      </div>

    </div>
    
  </div >
)
export default Payment
